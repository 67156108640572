<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">安全AI实时分析系统</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="username">
                    <el-input v-model="param.username" placeholder="username">
                        <template slot="prepend"><i class="el-icon-user"></i></template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                            type="password"
                            placeholder="password"
                            v-model="param.password"
                            @keyup.enter.native="submitForm()"
                    >
                        <template slot="prepend"><i class="el-icon-lock"></i></template>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
                <p class="login-tips">Tips : 请输入账户名和密码。</p>
            </el-form>
        </div>
        <div class="icp-footer">
            <span>©2022 杉谷科技有限公司 All Rights Reserved.</span>
            <span><a href="https://beian.miit.gov.cn/" target="_blank"
                     class="text-gray-800 text-hover-primary">粤ICP备2021057892号-3</a></span>
        </div>
    </div>
</template>

<script>
    import {loginLogic} from '../../api/requests.js'

    export default {
        data: function () {
            return {
                param: {
                    username: '',
                    password: '',
                },
                rules: {
                    username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                },
            };
        },
        methods: {
            submitForm() {
                this.$refs.login.validate(valid => {
                    if (valid) {
                        loginLogic(this.param).then(res => {
                            if (res.data.code !== 200) {
                                this.$message.error('输入账号或密码有误');
                                return false;
                            }
                            console.log(res.data.group)
                            console.log(res.data.info)
                            localStorage.setItem('Authorization', JSON.stringify(res.data));
                            this.$router.push('/');
                        })
                    } else {
                        this.$message.error('请输入账号和密码');
                        return false;
                    }
                });
            },
        },
    };
</script>

<style scoped>
    .login-wrap {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        background-size: cover;
        background-image: url(../assets/img/login-bg.jpg);
    }


    .ms-title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #fff;
        border-bottom: 1px solid #ddd;
    }

    .ms-login {
        margin-top: 30vh;
        margin-left: auto;
        margin-right: auto;
        width: 350px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.3);
    }

    .ms-content {
        padding: 30px 30px;
    }

    .login-btn {
        text-align: center;
    }

    .login-btn button {
        width: 100%;
        height: 36px;
        margin-bottom: 10px;
    }

    .login-tips {
        font-size: 12px;
        line-height: 30px;
        color: #fff;
    }

    .icp-footer {
        width: 100%;
        text-align: center;
        color: #F5F7FA;
        margin-top: 5vh;
    }
</style>